/**
 * This file is the source of truth for all colours used in the app, based on our design system.
 * They are used to maintain consistency across the app.
 * Do not update these colours unless you are updating the design system.
 */

/**
 * These are the base colors used in our design system.
 */
const BASE_COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828'
  },
  blue: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185'
  },
  red: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A'
  },
  purple: {
    25: '#FCFAFF',
    50: '#EBEAFF',
    100: '#DCDAFF',
    200: '#CDC9FE',
    300: '#ABA7FD',
    400: '#8782FC',
    500: '#6E63FA',
    600: '#5148D7',
    700: '#4434BF',
    800: '#3C27B4',
    900: '#2F08A1'
  },
  green: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31'
  },
  yellow: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDFB9',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E'
  }
}

/**
 * These are the color aliases used in our design system.
 * While they contain the same colors as the base colors, they are used to provide context to the color.
 */
const COLOR_ALIASES = {
  error: BASE_COLORS.red,
  primary: BASE_COLORS.purple,
  success: BASE_COLORS.green,
  warning: BASE_COLORS.yellow
}

/**
 * Used when the `enable_custom_datetime` feature flag is enabled.
 */
export const ALT_PRIMARY = {
  // Shades of hot pink
  25: '#FFF0F7',
  50: '#FEE9F2',
  100: '#FDC2DF',
  200: '#FA8EC6',
  300: '#F75AAE',
  400: '#F32D9B',
  500: '#F00088',
  600: '#D8007A',
  700: '#D8007A',
  800: '#D8007A',
  900: '#D8007A',
  A100: '#FF80AB',
  A200: '#FF4081',
  A400: '#F50057',
  A700: '#C51162'
}

const palette = {
  ...BASE_COLORS,
  ...COLOR_ALIASES
}

export default palette
