import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'
import { Resource } from '@opentelemetry/resources'
import {
  ExplicitBucketHistogramAggregation,
  MeterProvider,
  PeriodicExportingMetricReader,
  View
} from '@opentelemetry/sdk-metrics'
import {
  BatchSpanProcessor,
  TraceIdRatioBasedSampler,
  WebTracerProvider
} from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { escapeRegExp } from 'lodash-es'

import opentelemetry from '@opentelemetry/api'

import { APP_VERSION, OPENTELEMETRY_SERVER, SERVER, STAGE } from './Constants'

const initializeOpenTelemetry = () => {
  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'hypotenuse-frontend',
      [SemanticResourceAttributes.SERVICE_VERSION]: APP_VERSION,
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: STAGE
    })
  )

  if (STAGE === 'staging' || STAGE === 'prod') {
    const metricReader = new PeriodicExportingMetricReader({
      exporter: new OTLPMetricExporter({
        url: `${OPENTELEMETRY_SERVER}/v1/metrics`,
        headers: {}
      }),
      // Collect every 1 minute
      exportIntervalMillis: 60000
    })

    const view = new View({
      instrumentName: '*',
      aggregation: new ExplicitBucketHistogramAggregation([
        0,
        1000,
        3000,
        5000,
        10000,
        30000,
        50000,
        100000,
        300000,
        500000
      ])
    })

    const myServiceMeterProvider = new MeterProvider({
      resource: resource,
      readers: [metricReader],
      views: [view]
    })

    // Set this MeterProvider to be global to the app being instrumented.
    opentelemetry.metrics.setGlobalMeterProvider(myServiceMeterProvider)
  }

  const STAGE_TO_SAMPLING_RATE: { [key: string]: number } = {
    dev: 0,
    staging: 1,
    prod: 1
  }

  const provider = new WebTracerProvider({
    resource: resource,
    sampler: new TraceIdRatioBasedSampler(
      STAGE_TO_SAMPLING_RATE[STAGE || 'dev']
    )
  })
  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: `${OPENTELEMETRY_SERVER}/v1/traces`,
        headers: {}
      }),
      { scheduledDelayMillis: 60_000 }
    )
  )

  provider.register({
    // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
    contextManager: new ZoneContextManager()
  })

  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [
      new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls: [new RegExp(`${escapeRegExp(SERVER)}.*`)]
      }),
      new DocumentLoadInstrumentation()
    ]
  })
}

export { initializeOpenTelemetry }
