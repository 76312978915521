import React from 'react'

import '@fontsource/inter/variable.css'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import 'semantic-ui-css/semantic.min.css'
import SuperTokens from 'supertokens-auth-react'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import EmailVerification from 'supertokens-auth-react/recipe/emailverification'
import Session from 'supertokens-auth-react/recipe/session'
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty'

import '@hypotenuse/common/src/index.css'
import { FRONTEND_HOST, SERVER } from '@hypotenuse/common/src/utils/Constants'
import { initializeOpenTelemetry } from '@hypotenuse/common/src/utils/Telemetry'

import {
  INTERCOM_APP_ID,
  IS_DEPLOYED,
  POST_LOGIN_PAGE_PATH,
  SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
  STAGE,
  SUPERTOKENS_API_BASE_PATH,
  SUPERTOKENS_UI_BASE_PATH
} from './utils/Constants'

import AppContainer from './AppContainer'
import SuperTokensProvider from './components/SuperTokensProvider'
import './index.css'
import * as serviceWorker from './serviceWorker'

initializeOpenTelemetry()

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: 'Hypotenuse AI',
    apiDomain: SERVER,
    apiBasePath: SUPERTOKENS_API_BASE_PATH,
    websiteDomain: FRONTEND_HOST,
    websiteBasePath: SUPERTOKENS_UI_BASE_PATH
  },
  // Styling for the pre-built SuperTokens UI
  // See: https://supertokens.com/docs/thirdpartyemailpassword/common-customizations/styling/changing-style
  style: `
    [data-supertokens~=container] {
      margin: auto;
      width: 400px;
      border-radius: 5px;
      --palette-primary: 99, 93, 255;
      --palette-inputBackground: 255, 255, 255;
      --palette-textTitle: 30, 18, 26;
      --palette-textLabel: 30, 18, 26;
      --palette-textInput: 30, 18, 26;
      --palette-textLink: 99, 93, 255;
      --palette-textPrimary: 30, 18, 26;
      font-family: 'ULP Custom', -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
    }
    [data-supertokens~=superTokensBranding] {
      display: none;
    }
    [data-supertokens~=button] {
      --palette-primaryBorder: 99, 93, 255;
      --palette-primary: 39, 40, 156;
      height: 52px;
      font-weight: 400;
      font-family: inherit;
    }
    [data-supertokens~=button][data-supertokens~=providerButton] {
      justify-content: flex-start;
    }
    [data-supertokens~=linkButton] {
      font-family: inherit;
    }
    [data-supertokens~=secondaryLinkWithLeftArrow] {
      margin-bottom: 0;
      font-size: 16px;
      font-family: inherit;
    }
    [data-supertokens~=providerButtonText] {
      flex-grow: unset;
      font-size: 16px;
      font-family: inherit;
    }
    [data-supertokens~=input] {
      height: 52px;
      font-size: 16px;
      font-family: inherit;
      letter-spacing: normal;
    }
    [data-supertokens~=row] {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    [data-supertokens~=headerTitle] {
      margin: 24px 0 16px;
      font-weight: 400;
    }
    [data-supertokens~=authComponentList] {
      padding: 0;
    }
    [data-supertokens~=providerContainer] {
      padding: 0;
    }
    [data-supertokens~=inputWrapper] {
      height: 52px;
      border-radius: 8px;
    }
    [data-supertokens~=authComponentList]::after {
      display: block;
      padding-top: 20px;
      font-size: 12px;
      content: "Having trouble logging in? Contact us at support@hypotenuse.ai.";
    }
  `,
  // Allow use of password managers on the login UI
  // See: https://supertokens.com/docs/thirdpartyemailpassword/common-customizations/password-managers
  useShadowDom: false,
  getRedirectionURL: async (context) => {
    if (context.action === 'SUCCESS' && context.newSessionCreated) {
      if (
        context.redirectToPath === undefined ||
        !context.redirectToPath.startsWith(POST_LOGIN_PAGE_PATH)
      ) {
        // Force the redirection to `POST_LOGIN_PAGE_PATH` by default
        context.redirectToPath = POST_LOGIN_PAGE_PATH
      }
      return context.redirectToPath
    }
    return undefined
  },
  recipeList: [
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [Google.init()]
      }
    }),
    EmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: 'email',
              label: 'Email',
              nonOptionalErrorMsg: 'Please enter a valid email'
            },
            {
              id: 'password',
              label: 'Password',
              nonOptionalErrorMsg: 'Please enter a password'
            }
          ]
        },
        signInForm: {
          formFields: [
            {
              id: 'email',
              label: 'Email',
              nonOptionalErrorMsg: 'Please enter a valid email'
            },
            {
              id: 'password',
              label: 'Password',
              nonOptionalErrorMsg: 'Please enter a password'
            }
          ]
        }
      }
    }),
    EmailVerification.init({ mode: 'OPTIONAL' }),
    Session.init()
  ]
})

// Sentry logging for error debugging
if (IS_DEPLOYED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    environment: STAGE,
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    integrations: [new TracingIntegrations.BrowserTracing()]
  })
}

ReactDOM.render(
  // Don't add any context providers here - keep it as slim as possible
  <React.StrictMode>
    <SuperTokensProvider>
      <BrowserRouter>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <AppContainer />
        </IntercomProvider>
      </BrowserRouter>
    </SuperTokensProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
