import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import {
  Catalogue,
  CatalogueGenerationConfig,
  CatalogueHeadersResponse,
  CatalogueHeadersSet,
  CatalogueOverviewResponse,
  CatalogueOverviewSearchQuery,
  CatalogueTemplate,
  CatalogueUpdateForm
} from '../components/utils/Interfaces'

export const apiCreateCatalogue = async ({
  catalogueName,
  templateId,
  generationConfig,
  isHidden
}: {
  catalogueName: string
  templateId?: string
  generationConfig?: CatalogueGenerationConfig
  isHidden?: boolean
}) => {
  const response = await apiClient.post<{ catalogueId: string }>(
    '/catalogues/create',
    {
      template_id: templateId,
      generation_config: generationConfig,
      is_hidden: isHidden
    },
    {
      params: { catalogueName }
    }
  )
  return response.data
}

export const apiGetCatalogue = async (catalogueId: string) => {
  const response = await apiClient.get<Catalogue>(`/catalogues/${catalogueId}`)
  return response.data
}

export const apiGetCatalogueHeaders = (
  catalogueId: string
): Promise<CatalogueHeadersSet> => {
  /**
   * Return [catalogue_headers, required_headers]
   */
  return apiClient
    .get<CatalogueHeadersResponse>(`/catalogues/${catalogueId}/headers`)
    .then((resp) => {
      return {
        headers: new Set(resp.data.headers),
        required_headers: new Set(resp.data.required_headers)
      }
    })
}

export const apiGetCatalogueTemplateOfCatalogue = (
  catalogueId: string
): Promise<CatalogueTemplate | null> => {
  return apiClient
    .get<CatalogueTemplate | null>(
      `/catalogues/${catalogueId}/catalogue-template`
    )
    .then((resp) => resp.data)
}

export const logUnmatchedHeadersToSentry = (
  catalogueId: string,
  extraHeaders: string[],
  missingHeaders: string[]
) => {
  return apiClient.post<{ extraHeaders: string[]; missingHeaders: string[] }>(
    `/catalogues/${catalogueId}/log_unmatched_headers_to_sentry`,
    {
      extraHeaders,
      missingHeaders
    }
  )
}

interface CataloguesOverviewPayload {
  catalogueOverviewResponses: CatalogueOverviewResponse[]
  totalCatalogues: number
}

export const apiGetCatalogues = async (
  query: CatalogueOverviewSearchQuery
): Promise<CataloguesOverviewPayload> => {
  const response = await apiClient.post<CataloguesOverviewPayload>(
    '/catalogues/browse',
    query
  )
  let { catalogueOverviewResponses, totalCatalogues } = response.data
  return { catalogueOverviewResponses, totalCatalogues }
}

export const apiUpdateCatalogue = (
  catalogueUpdateForm: CatalogueUpdateForm
): Promise<Catalogue> => {
  const path = '/catalogues/' + catalogueUpdateForm.catalogueId + '/update'

  return apiClient
    .put(path, catalogueUpdateForm)
    .then((response) => response.data)
}

export const apiDeleteCatalogue = async (
  catalogueId: string
): Promise<void> => {
  void (await apiClient.delete<void>(
    `/catalogues/${encodeURIComponent(catalogueId)}`
  ))
}

export const apiGetCatalogueTemplates = async (): Promise<
  CatalogueTemplate[]
> => {
  const response = await apiClient.get<CatalogueTemplate[]>(
    '/catalogue-template'
  )
  return response.data
}
