import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import initHelpHero from 'helphero'
import { debounce } from 'lodash-es'
import posthog from 'posthog-js'
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation
} from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import useSWR from 'swr'

import palette, { ALT_PRIMARY } from '@hypotenuse/common/src/atoms/Colors'
import { Tooltip } from '@hypotenuse/common/src/atoms/Tooltip'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, CircularProgress, Button as MuiButton } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { useAnalytics } from '@hypotenuse/common/src/analytics/Analytics'
import { apiAcceptOrganizationInvite } from '@hypotenuse/common/src/api/Organization'
import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import { getGlobalAppData } from '@hypotenuse/common/src/api/Settings'
import { getUserOnboardInfo } from '@hypotenuse/common/src/api/User'
import { marketingRoutes } from '@hypotenuse/common/src/components/ToolLibrary'
import { ErrorFallback } from '@hypotenuse/common/src/components/error/ErrorFallback'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'
import { useContentRecommendation } from '@hypotenuse/common/src/hooks/useContentRecommendation'
import { GenerationAPIProvider } from '@hypotenuse/common/src/hooks/useGenerationAPI'
import { useWordUsage } from '@hypotenuse/common/src/hooks/useWordUsage'
import {
  GtmDataLayerTypes,
  GtmEvents
} from '@hypotenuse/common/src/integrations/GoogleTagManager/Interfaces'
import { BeaconProvider } from '@hypotenuse/common/src/integrations/HelpScout'
import {
  AdTextType,
  adTextTabs
} from '@hypotenuse/common/src/interfaces/AdvertisingText'
import { ProtectedRouteWithRedirect } from '@hypotenuse/common/src/utils/ProtectedRoute'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'
import { CatalogueTemplateProvider } from '@hypotenuse/common/src/utils/context/CatalogueTemplateContext'
import { UserProvider } from '@hypotenuse/common/src/utils/context/UserContext'

import { useTeamInviteId } from './components/teams/hooks/useTeamInviteDetails'
import useNewVersionRefreshSnackbar from './hooks/useNewVersionRefreshSnackbar'
import usePremiumPlanTier from './hooks/usePremiumPlanTier'

import { handleTrackUserSession } from './api/Analytics'
import { apiGetCatalogueTemplates } from './api/Catalogue'
import { apiGetIntercomSessionHmac } from './api/Intercom'
import {
  apiFetchUser,
  apiGetIsPhoneVerified,
  upsertUserOnboardInfoForm
} from './api/User'

import {
  CatalogueTemplate,
  User,
  UserOnboardingInfoForm
} from './components/utils/Interfaces'
import {
  AMPLITUDE_API_KEY,
  EMAIL_VERIFICATION_PAGE_PATH,
  GTM_AUTH,
  GTM_CONTAINER_ID,
  GTM_PREVIEW,
  HELPHERO_APP_ID,
  HELPSCOUT_BEACON_ID,
  POSTHOG_API_KEY,
  PUBLIC_POSTHOG_URL,
  STAGE
} from './utils/Constants'
import { isWordsEnabled } from './utils/Functions'
import theme from './utils/Theme'
import { GLOBAL_APP_ACTION } from './utils/actions/GlobalAppContext'
import { useGlobalAppContext } from './utils/context/GlobalAppContext'
import { OnboardingContextProvider } from './utils/context/OnboardingContext'
import { useTrialUserContext } from './utils/context/TrialUserContext'

import IntercomIcon from './assets/icons/intercom-icon.svg'
import { ApiErrorModal } from './components/ApiErrorModal'
import { PremiumInsufficientCreditsModal } from './components/PremiumInsufficientCreditsModal'
import { PremiumInsufficientWordsModal } from './components/PremiumInsufficientWordsModal'
import { WelcomeMemberModal } from './components/main/organization/WelcomeMemberModal'
import PaymentFailureModal from './components/main/payment/PaymentFailureModal'
import {
  RatelimitCTAModal,
  UserPlanType
} from './components/main/trial/RatelimitCTAModal'
import { G2Modal } from './components/netPromoterScore/G2Modal'

/**
 * Initialize GTM when the app loads
 */
const initializeGtm = () => {
  // Google Tag Manager initialization
  // See: https://github.com/alinemorelli/react-gtm
  const tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_CONTAINER_ID,
    dataLayerName: GtmDataLayerTypes.GTM_SUBSCRIPTION_LAYER, // Name of any data layer we want to pass.
    events: {
      // Events that can be triggered must be defined here.
      phoenixCheckoutStarted: GtmEvents.PHOENIX_CHECKOUT_STARTED,
      phoenixCheckoutSuccess: GtmEvents.PHOENIX_CHECKOUT_SUCCESS,
      phoenixEmailVerified: GtmEvents.PHOENIX_EMAIL_VERIFIED
    },
    preview: GTM_PREVIEW, // Used for testing using GTM
    auth: GTM_AUTH // Used for testing using GTM
  }

  try {
    TagManager.initialize(tagManagerArgs)
  } catch (e) {
    // App should not break if TagManager fails
    Sentry.captureException(e)
  }
}

declare global {
  interface Window {
    // Typing for FirstPromoter integration
    fpr: (method: string, options: any) => void
  }
}

const Dashboard = React.lazy(() => import('./components/pages/Dashboard'))
const LockedOutPage = React.lazy(
  () => import('./components/pages/LockedOutPage')
)
const CataloguePage = React.lazy(
  () => import('./components/pages/CataloguePage')
)
const CatalogueView = React.lazy(
  () => import('./components/main/CatalogueView')
)
const AdvertisingTextPage = React.lazy(
  () => import('./components/pages/AdvertisingTextPage')
)
const BulkWorkflowDashboardPage = React.lazy(
  () => import('./components/pages/BulkWorkflowDashboardPage')
)
const BulkWorkflowPage = React.lazy(
  () => import('./components/pages/BulkWorkflowPage')
)
const BlogGeneratorPage = React.lazy(
  () => import('./components/pages/BlogGeneratorPage')
)
const SpeechPage = React.lazy(
  () => import('./components/pages/LongFormContentPages/SpeechPage')
)
const AutomapperPage = React.lazy(
  () => import('./components/pages/AutomapperPage')
)
const DocumentOverviewPage = React.lazy(
  () => import('./components/pages/DocumentOverviewPage')
)
const ChatPage = React.lazy(() => import('./components/pages/ChatPage'))
const LiveFeedPage = React.lazy(() => import('./components/pages/LiveFeedPage'))
const TextToImagePage = React.lazy(
  () => import('./components/pages/TextToImagePage')
)
const PremiumDrawerWithNavBar = React.lazy(
  () => import('./components/main/drawer/PremiumDrawerWithNavBar')
)
const Settings = React.lazy(
  () => import('./components/pages/settings/Settings')
)
const PricingPage = React.lazy(
  () => import('./components/pages/settings/pricing/PricingPage')
)
const BillingPage = React.lazy(
  () => import('./components/pages/settings/billing/Billing')
)
const UserManagement = React.lazy(
  () => import('./components/pages/settings/UserManagement')
)
const EmailVerificationPage = React.lazy(
  () => import('./components/pages/EmailVerificationPage')
)
const OnboardingRedirect = React.lazy(
  () => import('./components/pages/OnboardingRedirect')
)
const PhoneVerification = React.lazy(
  () => import('./components/pages/PhoneVerification')
)
const OnboardingModal = React.lazy(
  () => import('./components/main/onboarding/OnboardingModal')
)
const FreeCredits = React.lazy(
  () => import('@hypotenuse/common/src/pages/FreeCredits')
)
const Faq = React.lazy(() => import('./components/pages/Faq'))
const NotFound = React.lazy(() => import('./components/pages/NotFound'))
const AdminPanelPage = React.lazy(
  () => import('./components/pages/AdminPanelPage')
)
const CheckoutSuccessPage = React.lazy(
  () => import('./pages/CheckoutSuccessPage')
)
const ShopifyIntegrationConfirmationPage = React.lazy(
  () => import('./pages/ShopifyIntegrationConfirmationPage')
)
const WebflowAuthorizationPage = React.lazy(
  () => import('./components/pages/settings/WebflowAuthorizePage')
)

const ShopifyUpgradePlanConfirmationPage = React.lazy(
  () => import('./pages/ShopifyUpgradePlanConfirmationPage')
)

const DocumentPage = React.lazy(() => import('./components/pages/DocumentPage'))
const FreeWords = React.lazy(() => import('./components/pages/FreeWords'))

const CollectionsPage = React.lazy(
  () => import('./components/pages/CollectionsPage')
)
const CollectionView = React.lazy(
  () => import('./components/pages/CollectionView')
)
const AssetsPage = React.lazy(() => import('./components/pages/AssetsPage'))

const ProductDatabasePage = React.lazy(
  () => import('./components/pages/ProductDatabasePage')
)

const ImageWorkflowPage = React.lazy(
  () => import('./components/pages/ImageWorkflowPage')
)
const ImageEditingPage = React.lazy(
  () => import('@hypotenuse/common/src/pages/ImageEditingPage')
)
const EcommercePage = React.lazy(
  () => import('./components/pages/EcommercePage')
)
const DigitalShelfAnalyticsPage = React.lazy(
  () => import('./components/pages/DigitalShelfAnalyticsPage')
)
const ProductMonitoringConversionPage = React.lazy(
  () => import('./components/pages/ProductMonitoringConversionPage')
)
const PremiumProjectPage = React.lazy(
  () => import('./components/pages/ProjectPage')
)

const FullPageLoader = () => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress size="3rem" />
  </div>
)
const hlp = initHelpHero(HELPHERO_APP_ID)
amplitude.init(AMPLITUDE_API_KEY)
posthog.init(POSTHOG_API_KEY, {
  api_host: PUBLIC_POSTHOG_URL,
  // On frontend app, only record users who have been identified so that
  // we can identify logs that have been stitched together on identification.
  // See: https://posthog.com/docs/getting-started/person-properties
  person_profiles: 'identified_only',
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
  disable_session_recording: STAGE === 'dev',
  loaded: () => {
    if (STAGE === 'dev') {
      posthog.debug()
    }
  },
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true
    }
  }
})

const useStyles = makeStyles(() =>
  createStyles({
    intercomButton: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      color: 'white',
      '& circle': {
        fill: palette.gray[900]
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })
)
export default function App() {
  useNewVersionRefreshSnackbar()
  const [user, setUser] = useState<User>()
  const [isShowingG2Modal, setIsShowingG2Modal] = useState(false)
  const [
    isShowingG2ReviewDrawerItems,
    setIsShowingG2ReviewDrawerItems
  ] = useState(false)
  const { data: userPlanTier } = usePremiumPlanTier()
  const { plan_id, plan_type } = userPlanTier ?? {}
  const { update: updateIntercom, boot: _bootIntercom } = useIntercom()

  const {
    setUsername: setAnalyticsUsername,
    setUserPlanType: setAnalyticsUserPlanType,
    trackPageView
  } = useAnalytics()

  const refreshUser = useCallback(async () => {
    return apiFetchUser()
      .then((user) => {
        setUser(user)
        setAnalyticsUsername(user.username)
        handleTrackUserSession().catch((_error) => {
          // We don't want to throw an error if the API is called after user has logged out
          // 405 status code happens because of the following events:
          // - Since user has logged out, the cookie is not valid, so the API will return 307
          // - Backend will redirect the call to / endpoint with the POST method, since / endpoint only supports get,
          // it will return 405
          // Ideally we don't want to call the function at all, but since it will take
          // more time to conditionally call the API, we will at least prevent the error
          // from apprearing in Sentry
          if (
            !(axios.isAxiosError(_error) && _error.response?.status === 405)
          ) {
            Sentry.captureMessage('Track user session failed', 'warning')
          }
        })
      })
      .catch((_error) => {
        snackbar.show(
          'Failed to fetch your data, please check your connection.',
          {
            variant: 'error',
            action: (snackbarKey) => (
              <>
                <MuiButton
                  color="inherit"
                  size="small"
                  onClick={() => window.location.reload()}
                  style={{ fontWeight: 'bold' }}
                >
                  Refresh
                </MuiButton>
                <MuiButton
                  color="inherit"
                  size="small"
                  onClick={() => snackbar.close(snackbarKey)}
                  style={{ fontWeight: 'bold' }}
                >
                  Dismiss
                </MuiButton>
              </>
            )
          }
        )
        console.error(_error)
      })
  }, [setAnalyticsUsername])

  const [showTeamWelcomeModal, setShowTeamWelcomeModal] = useState<boolean>(
    false
  )
  const [teamInviteId, setTeamInviteId] = useTeamInviteId()
  useEffect(() => {
    const tryJoinTeam = async () => {
      if (teamInviteId) {
        // Call API to add user to team
        const newOrgMember = await apiAcceptOrganizationInvite(teamInviteId)
          .catch((err) => {
            if (axios.isAxiosError(err)) {
              // If the error is a 403, it means the invite was not meant for this user.
              if (err.response?.status === 403) {
                console.error(
                  'Got 403 error while trying to join team. This likely means the invite was not meant for this user.'
                )
                return undefined
              }
            } else throw err
          })
          .finally(() => {
            // Regardless of success or failure, remove the invite ID from localStorage.
            // If the user wants to retry, they will need to click the invite link again.
            setTeamInviteId(undefined)
          })
        if (!newOrgMember) return
        console.debug(`Added user to team ${newOrgMember.organization_id}`)

        // Show the welcome modal. Since this state is persisted as a query param,
        // it will be shown on the next page load until the user closes it.
        setShowTeamWelcomeModal(true)

        // Refresh the user to reflect the change in the user's active team.
        await refreshUser()
      } else {
        console.debug('No team invite found in localStorage.')
      }
    }
    tryJoinTeam()
  }, [teamInviteId, setTeamInviteId, setShowTeamWelcomeModal, refreshUser])

  const classes = useStyles()
  const isMobile = useIsMobile()
  /**
   * Users for whom whiteLabeling is enabled should not see any branding on
   *  the site. This includes things like chat & support widgets, etc.
   * This is meant to be a temporary solution until we can implement a more
   * granular white-labeling solution.
   * If the user isn't loaded yet we assume they are white-labeled to avoid flashing the branding.
   */
  const whiteLabelUi =
    !user || user?.service_configs?.feature_flags?.white_label_ui
  const enableContentRecommendation =
    user?.service_configs?.feature_flags?.enable_action_item_recommendation
  // These flags are split up here so that they can be
  //  individually toggled in the future.
  /**
   * Removes the call-to-action button in the 'insufficient credits' modal
   */
  const disableInsufficientCreditsRedirectButton = !!whiteLabelUi
  const disableInsufficientWordsRedirectButton = !!whiteLabelUi
  /**
   * Disables the rate-limit modal
   */
  const disableRateLimitCtaModal = !!whiteLabelUi
  const disableReferralPage = !!whiteLabelUi
  const disableSettingsPage = !!whiteLabelUi
  const disableFaqPage = !!whiteLabelUi
  /**
   * Disables the onboarding page & 'complete your profile' button
   */
  const disableOnboarding = !!whiteLabelUi
  /**
   * Removes Beacon article links & `help.hypotenuse.ai` link in sidebar
   */
  const disableHelpScout = !!whiteLabelUi
  /**
   * Disables support chat widget
   */
  const disableChatSupport = !!whiteLabelUi

  const { isTrial, isEnterpriseUser, isUnlimitedUser } = useTrialUserContext()
  const [
    showRateLimitExceededModal,
    setShowRateLimitExceededModal
  ] = useState<boolean>(false)

  const [
    canShowPaymentFailureModal,
    setCanShowPaymentFailureModal
  ] = useState<boolean>(true) // Defaults to true,is false only when user closes pop-up on that page
  const handlePaymentFailureModalClose = useCallback(
    () => setCanShowPaymentFailureModal(false),
    []
  )

  const emailVerified = user?.email_verified ?? false

  const location = useLocation()
  const locationPath = location.pathname

  const isIntercomBooted = React.useRef(false)

  useEffect(() => initializeGtm(), [])

  useEffect(() => {
    if (!disableChatSupport) {
      // Initialize Intercom
      _bootIntercom({
        customAttributes: {
          vertical_padding: theme.spacing(9),
          custom_launcher_selector: '.custom-intercom-launcher'
        }
      })
      isIntercomBooted.current = true
    }
  }, [_bootIntercom, disableChatSupport])

  const updateIntercomWithSessionHmac = useCallback(async () => {
    if (user && plan_id) {
      return apiGetIntercomSessionHmac().then((hmac) => {
        updateIntercom({
          name: user.displayName || user.username,
          email: user.username,
          userId: user.username,
          createdAt: user.createdAt,
          userHash: hmac,
          customAttributes: {
            activePlan: plan_id
          }
        })
      })
    }
  }, [updateIntercom, user, plan_id])

  useEffect(() => {
    if (isIntercomBooted.current) {
      updateIntercomWithSessionHmac()
    }
  }, [updateIntercomWithSessionHmac])

  const {
    state: { globalFeatureFlags },
    dispatch
  } = useGlobalAppContext()

  const isHypoFeedEnabled: boolean = globalFeatureFlags.enable_hypofeed ?? false

  const isProductDatabaseEnabled =
    user?.service_configs?.feature_flags?.enable_product_database_tab ?? false

  const isAssetsManagementEnabled =
    user?.service_configs?.feature_flags?.enable_assets_management_tab ?? false

  const isProductMonitoringEnabled =
    user?.service_configs?.feature_flags?.enable_product_monitoring_tab ?? false

  const isImageWorkflowEnabled =
    user?.service_configs?.feature_flags?.enable_image_workflow_tab ?? false

  const isAutomapperEnabled =
    user?.service_configs?.feature_flags?.enable_automapper ?? false

  const isWordsFeatureEnabled = user ? isWordsEnabled(user) : false

  const [onboardingInfo, setOnboardingInfo] = useState<UserOnboardingInfoForm>()

  const {
    isLoading: isPhoneNumberVerifiedLoading,
    data: isPhoneNumberVerified,
    mutate: mutateIsPhoneNumberVerified
  } = useSWR(
    user ? [`/auth/mfa/phone/is-verified`, user.username] : null,
    apiGetIsPhoneVerified
  )

  const { data: planTierListObject } = useSWR(
    'planTierListObject',
    apiFetchPlanTierListObject
  )

  /**
   * Fetches the global app data from the db and stores it in the context level
   */
  const refreshGlobalAppData = useCallback(() => {
    getGlobalAppData()
      .then((data) => {
        // downvote reasons
        const defaultDownvoteReasons = data.default_description_downvote_reasons
        if (defaultDownvoteReasons) {
          dispatch({
            type: GLOBAL_APP_ACTION.DEFAULT_DOWNVOTE_REASONS,
            payload: defaultDownvoteReasons
          })
        }
        // blog gen helper text
        const blogGenHelperText = data.blog_gen_helper_text
        if (blogGenHelperText) {
          dispatch({
            type: GLOBAL_APP_ACTION.BRAND_HELPER_TEXT,
            payload: blogGenHelperText?.brandBackgroundHelperText ?? ''
          })
          dispatch({
            type: GLOBAL_APP_ACTION.BRAND_PLACEHOLDER_TEXT,
            payload: blogGenHelperText?.brandBackgroundPlaceholder ?? ''
          })
        }

        // blog tone options
        const blogToneOptions = data.blog_tone_options
        if (blogToneOptions) {
          dispatch({
            type: GLOBAL_APP_ACTION.BLOG_TONE_OPTIONS,
            payload: blogToneOptions
          })
        }

        const blogLengthOptions = data.blog_length_options
        if (blogLengthOptions) {
          dispatch({
            type: GLOBAL_APP_ACTION.BLOG_LENGTH_OPTIONS,
            payload: blogLengthOptions
          })
        }

        // global feature flags
        const globalFeatureFlags = data.global_feature_flags
        if (globalFeatureFlags) {
          dispatch({
            type: GLOBAL_APP_ACTION.GLOBAL_FEATURE_FLAGS,
            payload: globalFeatureFlags
          })
        }
      })
      .catch((error) => {
        console.error('Failed to fetch global settings:', error)
      })
  }, [dispatch])
  const debouncedRefreshGlobalAppData = useMemo(
    () =>
      debounce(refreshGlobalAppData, 2000, {
        leading: true,
        trailing: true,
        maxWait: 10_000
      }),
    [refreshGlobalAppData]
  )

  useEffect(() => {
    setAnalyticsUserPlanType(plan_type)
  }, [setAnalyticsUserPlanType, plan_type])

  useEffect(() => {
    // Listen to and track different pages that user is visiting
    trackPageView(locationPath)
  }, [trackPageView, locationPath])

  useEffect(() => {
    // Refetch the global app data when the user navigates to a new page.
    // This is to ensure that the global app data is always up to date.
    // Many times, when the user navigates to a new page, the global app data
    // is not fetched and propagated to the child components, and the data is stale.
    // Debouncing prevents spamming the server with requests.
    debouncedRefreshGlobalAppData()
  }, [debouncedRefreshGlobalAppData, /* effect dep */ locationPath])

  const onGenerationRateLimitExceeded = useCallback(() => {
    // When a user exceeds a rate limit, we show a call-to-action modal.
    // If the user is in the trial, we ask the user to subscribe.
    // If the user is on a paid plan, we ask them to upgrade.
    setShowRateLimitExceededModal(true)
  }, [])

  useEffect(() => {
    void refreshUser()
    // Get the global level app data from the db and store it in the context level
    refreshGlobalAppData()

    getUserOnboardInfo()
      .then((resp) => {
        setOnboardingInfo(resp)
      })
      .catch((error) => {
        console.error('Failed to fetch onboarding information:', error)
        snackbar.show(
          'Something went wrong. Please contact our support team.',
          {
            variant: 'error'
          }
        )
      })
  }, [refreshUser, refreshGlobalAppData])

  const enableCustomPalette: boolean =
    user?.service_configs?.feature_flags?.enable_custom_palette ?? false
  useEffect(() => {
    // VERY IMPORTANT: Do not run any further code if the feature flag is not enabled.
    // This should not affect any real users.
    if (!enableCustomPalette) return

    const oldPrimary = palette.primary
    const oldPurple = palette.purple
    const oldGray = palette.gray
    const oldWhite = palette.white
    const oldBlack = palette.black

    const oldThemePrimary = theme.palette.primary
    const oldThemeSecondary = theme.palette.secondary
    const oldThemeGrey = theme.palette.grey
    const oldThemeText = theme.palette.text
    const oldThemeBackground = theme.palette.background
    const oldThemeDivider = theme.palette.divider
    const oldThemeWhite = theme.palette.common.white
    const oldThemeBlack = theme.palette.common.black

    const styleElement = document.createElement('style')
    document.head.appendChild(styleElement)
    styleElement.innerHTML = `
      html, body {
        background-color: ${ALT_PRIMARY[25]} !important;
        color: ${ALT_PRIMARY[700]} !important;
      }
      a {
        color: ${ALT_PRIMARY[500]} !important;
      }
      h1, h2, h3, h4, h5, h6, p, label {
        color: ${ALT_PRIMARY[900]} !important;
      }
      svg {
        stroke: ${ALT_PRIMARY[500]} !important;
      }
      `

    palette.primary = ALT_PRIMARY
    palette.purple = ALT_PRIMARY
    palette.gray = ALT_PRIMARY
    palette.white = ALT_PRIMARY[50]
    palette.black = ALT_PRIMARY[900]

    theme.palette.primary = {
      ...ALT_PRIMARY,
      main: ALT_PRIMARY[500],
      light: ALT_PRIMARY[300],
      dark: ALT_PRIMARY[700],
      contrastText: '#fff'
    }
    theme.palette.secondary = {
      ...ALT_PRIMARY,
      main: ALT_PRIMARY[500],
      light: ALT_PRIMARY[300],
      dark: ALT_PRIMARY[700],
      contrastText: '#fff'
    }
    theme.palette.grey = ALT_PRIMARY
    theme.palette.text = {
      primary: ALT_PRIMARY[900],
      secondary: ALT_PRIMARY[700],
      disabled: ALT_PRIMARY[100],
      hint: ALT_PRIMARY[200]
    }
    theme.palette.background = {
      default: ALT_PRIMARY[50],
      paper: ALT_PRIMARY[25]
    }
    theme.palette.divider = ALT_PRIMARY[100]
    theme.palette.common.white = ALT_PRIMARY[50]
    theme.palette.common.black = ALT_PRIMARY[900]

    return () => {
      // Cleanup
      document.head.removeChild(styleElement)

      palette.primary = oldPrimary
      palette.purple = oldPurple
      palette.gray = oldGray
      palette.white = oldWhite
      palette.black = oldBlack

      theme.palette.primary = oldThemePrimary
      theme.palette.secondary = oldThemeSecondary
      theme.palette.grey = oldThemeGrey
      theme.palette.text = oldThemeText
      theme.palette.background = oldThemeBackground
      theme.palette.divider = oldThemeDivider
      theme.palette.common.white = oldThemeWhite
      theme.palette.common.black = oldThemeBlack
    }
  }, [enableCustomPalette])

  const [isPrankd, setIsPrankd] = useState(false)
  const isCustomDatetimeEnabled: boolean =
    user?.service_configs?.feature_flags?.enable_custom_datetime ?? false
  useEffect(() => {
    // VERY IMPORTANT: Do not run any further code if the feature flag is not enabled.
    // This should not affect any real users.
    if (!isCustomDatetimeEnabled) return

    // On any click anywhere, have a 50% chance of setting isPrankd to true
    const clickListener = () => {
      if (!isPrankd && Math.random() < 0.5) {
        setIsPrankd(true)
      }
    }
    document.addEventListener('click', clickListener)

    return () => {
      // Cleanup
      document.removeEventListener('click', clickListener)
    }
  }, [isCustomDatetimeEnabled, isPrankd])

  const { getRecommendedContent } = useContentRecommendation()

  useEffect(() => {
    if (!enableContentRecommendation) return
    getRecommendedContent()
  }, [getRecommendedContent, enableContentRecommendation])

  const isLoadingData = !user || !onboardingInfo || isPhoneNumberVerifiedLoading
  const renderPageLoader = () => {
    const pathsWithLoader = [
      '/home',
      '/product-descriptions',
      '/product-monitoring',
      '/documents',
      '/catalog',
      '/image-generation',
      '/settings'
    ]
    return pathsWithLoader.includes(window.location.pathname) &&
      isLoadingData ? (
      <FullPageLoader />
    ) : null
  }

  const hasSeenOnboarding = onboardingInfo?.has_completed

  // enterprise users should not be locked to pricing page
  // similar to current behaviour of hiding PaymentFailureModal for them
  const restrictToPricingPage =
    !userPlanTier?.isEnterprisePlan &&
    !!userPlanTier?.payment_failure_info?.restrictToPricingPage

  // function to update user onboarding information
  const updateOnboardingInfo = useCallback(
    async (
      info: UserOnboardingInfoForm,
      isOnboardingFormSubmission: boolean
    ) => {
      try {
        const newUserOnboardingInfo = await upsertUserOnboardInfoForm(
          info,
          isOnboardingFormSubmission
        )
        // Track referrals from FirstPromoter affiliate links.
        // The FirstPromoter script is loaded in the index.html file.
        window.fpr('referral', { email: user?.username })
        setOnboardingInfo(newUserOnboardingInfo)
      } catch (e) {
        snackbar.show(
          'Something went wrong. Please contact our support team.',
          {
            variant: 'error'
          }
        )
        console.error('Failed to track referral')
        Sentry.captureException(e, {
          tags: {
            email: user?.username
          }
        })
      }
      void refreshUser()
    },
    [refreshUser, user?.username]
  )

  /**
   * Triggers a re-validation of the word usage
   */
  const getWordUsage = useWordUsage((state) => state.getWordUsage)

  const handleAfterGenerate = useCallback(async () => {
    if (user && isWordsFeatureEnabled) {
      await getWordUsage(user.organizationName, user.username)
    }
  }, [getWordUsage, isWordsFeatureEnabled, user])

  const {
    data: catalogueTemplates,
    isLoading: isLoadingCatalogueTemplates
  } = useSWR<CatalogueTemplate[]>(
    `/catalogue-template`,
    async () => {
      try {
        return await apiGetCatalogueTemplates()
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        // TODO: decide whether or not to notify the user
        return []
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true
    }
  )

  const rewriterTemplateId = useMemo(
    () =>
      catalogueTemplates?.find(
        (template) => template.input_mapping.product_page_url
      )?.id,
    [catalogueTemplates]
  )

  return user ? (
    <UserProvider value={{ user, refreshUser }}>
      <BeaconProvider
        beaconId={HELPSCOUT_BEACON_ID}
        disableBeaconLinks={disableHelpScout}
      >
        <GenerationAPIProvider
          onRateLimitExceeded={onGenerationRateLimitExceeded}
          username={user.username}
          planListId={planTierListObject?.plan_list_id}
        >
          {/*
          Call-to-action modal displayed when user exceeds a rate-limit.
          Note: This modal will not open for white-label users
          */}

          <RatelimitCTAModal
            open={showRateLimitExceededModal && !disableRateLimitCtaModal}
            onClose={() => setShowRateLimitExceededModal(false)}
            userPlanType={
              isTrial
                ? UserPlanType.trial
                : isEnterpriseUser
                ? UserPlanType.enterprise
                : isUnlimitedUser
                ? UserPlanType.unlimited
                : UserPlanType.paid
            }
            user={user}
            refreshUser={refreshUser}
          />
          {/* Show the welcome modal the first time a user logs in after joining a team */}
          <WelcomeMemberModal
            open={
              showTeamWelcomeModal && !!hasSeenOnboarding && !user.is_locked_out
            }
            onClose={() => setShowTeamWelcomeModal(false)}
          />
          <div style={{ height: '100%' }}>
            <Box
              className="custom-intercom-launcher"
              style={{
                position: 'fixed',
                bottom: '0',
                right: '0',
                margin: theme.spacing(3),
                zIndex: 1800,
                cursor: 'pointer'
              }}
            >
              <Tooltip
                title={
                  <Typography variant="body1"> Help and resources</Typography>
                }
                placement="top"
                style={{ minWidth: 'fit-content' }}
              >
                <img
                  src={IntercomIcon}
                  className={classes.intercomButton}
                  alt="Intercom icon"
                />
              </Tooltip>
            </Box>
            {renderPageLoader()}
            <ApiErrorModal user={user} refreshUser={refreshUser} />
            {canShowPaymentFailureModal && (
              <PaymentFailureModal
                markModalAsSeen={handlePaymentFailureModalClose}
              />
            )}
            {!isWordsFeatureEnabled && (
              <PremiumInsufficientCreditsModal
                disableRedirectButton={disableInsufficientCreditsRedirectButton}
              />
            )}
            {isWordsFeatureEnabled && (
              <PremiumInsufficientWordsModal
                disableRedirectButton={disableInsufficientWordsRedirectButton}
              />
            )}
            <OnboardingContextProvider
              user={user}
              onboarding_info={onboardingInfo}
              onOnboardingStepSubmit={updateOnboardingInfo}
            >
              {
                <Suspense fallback={<FullPageLoader />}>
                  <PremiumDrawerWithNavBar
                    refreshUser={refreshUser}
                    isPhoneNumberVerified={!!isPhoneNumberVerified}
                    isShowingG2ReviewDrawerItems={isShowingG2ReviewDrawerItems}
                    setIsShowingG2ReviewDrawerItems={
                      setIsShowingG2ReviewDrawerItems
                    }
                    setIsShowingG2Modal={setIsShowingG2Modal}
                  >
                    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                      <Switch>
                        {/* This route will take precedence over all others because it appears
                        first inside the switch and matches all URL paths */}
                        {isPrankd && (
                          <Route path="" component={ErrorFallback} />
                        )}
                        <Route
                          path="/settings/user-management"
                          component={UserManagement}
                        />
                        <Route path="/settings/pricing">
                          <PricingPage user={user} refreshUser={refreshUser} />
                        </Route>
                        <Route path="/settings/billing">
                          <BillingPage user={user} />
                        </Route>
                        <Route path="/locked" component={LockedOutPage} />
                        <ProtectedRouteWithRedirect
                          hasAccess={!user.is_locked_out}
                          redirectUrl="/locked"
                        >
                          <ProtectedRouteWithRedirect
                            hasAccess={!restrictToPricingPage}
                            redirectUrl="/settings/billing"
                          >
                            <Switch>
                              <ProtectedRouteWithRedirect
                                hasAccess={!disableFaqPage}
                                redirectUrl="/home"
                                path="/faq"
                                component={Faq}
                              />
                              <Route
                                path={EMAIL_VERIFICATION_PAGE_PATH}
                                render={() => (
                                  <EmailVerificationPage
                                    updateIsPhoneNumberVerified={
                                      mutateIsPhoneNumberVerified
                                    }
                                  />
                                )}
                              />

                              <ProtectedRouteWithRedirect
                                hasAccess={emailVerified}
                                redirectUrl={EMAIL_VERIFICATION_PAGE_PATH}
                              >
                                <Route
                                  path="/phone-verification"
                                  render={() => (
                                    <PhoneVerification
                                      isPhoneNumberVerifiedLoading={
                                        isPhoneNumberVerifiedLoading
                                      }
                                      isPhoneNumberVerified={
                                        isPhoneNumberVerified
                                      }
                                      setIsPhoneNumberVerified={
                                        mutateIsPhoneNumberVerified
                                      }
                                    />
                                  )}
                                />
                                <ProtectedRouteWithRedirect
                                  hasAccess={
                                    !user.requires_phone_verification ||
                                    isPhoneNumberVerified !== false
                                  }
                                  redirectUrl="/phone-verification"
                                >
                                  <OnboardingModal
                                    open={!isLoadingData && !hasSeenOnboarding}
                                  />
                                  <CatalogueTemplateProvider
                                    value={{
                                      rewriterTemplateId: rewriterTemplateId
                                    }}
                                  >
                                    <Switch>
                                      <ProtectedRouteWithRedirect
                                        path="/free-credits"
                                        exact
                                        hasAccess={!disableReferralPage}
                                        redirectUrl="/home"
                                        render={() => (
                                          <FreeCredits
                                            enableSocialShare={
                                              user.service_configs
                                                .dashboard_incentive_options
                                                ?.linkedin_post
                                            }
                                          />
                                        )}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/free-words"
                                        exact
                                        hasAccess={!disableReferralPage}
                                        redirectUrl="/home"
                                        component={FreeWords}
                                      />
                                      <Route
                                        path={['', '/home']}
                                        exact
                                        render={() => (
                                          <Dashboard
                                            setIsShowingG2Modal={
                                              setIsShowingG2Modal
                                            }
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/product-descriptions"
                                        exact
                                        render={(
                                          props: RouteComponentProps<{
                                            [x: string]: string | undefined
                                          }>
                                        ) => (
                                          // TODO: Typing for RouteComponentProps
                                          <CataloguePage
                                            {...props}
                                            catalogueTemplates={
                                              catalogueTemplates
                                            }
                                            isLoadingCatalogueTemplates={
                                              isLoadingCatalogueTemplates
                                            }
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/ecommerce"
                                        component={EcommercePage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/assets-management"
                                        redirectUrl="/home"
                                        exact
                                        hasAccess={isAssetsManagementEnabled}
                                        component={AssetsPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/product-monitoring"
                                        redirectUrl="/home"
                                        hasAccess={isProductMonitoringEnabled}
                                        component={DigitalShelfAnalyticsPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/product-monitoring-conversion"
                                        redirectUrl="/home"
                                        hasAccess={isProductMonitoringEnabled}
                                        component={
                                          ProductMonitoringConversionPage
                                        }
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/product-database"
                                        redirectUrl="/home"
                                        hasAccess={isProductDatabaseEnabled}
                                        render={(
                                          props: RouteComponentProps<{
                                            [x: string]: string | undefined
                                          }>
                                        ) => (
                                          // TODO: Typing for RouteComponentProps
                                          <ProductDatabasePage
                                            {...props}
                                            catalogueTemplates={
                                              catalogueTemplates
                                            }
                                            isLoadingCatalogueTemplates={
                                              isLoadingCatalogueTemplates
                                            }
                                          />
                                        )}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/project/:projectId"
                                        redirectUrl="/home"
                                        hasAccess={isProductDatabaseEnabled}
                                        render={(
                                          props: RouteComponentProps<{
                                            [x: string]: string | undefined
                                          }>
                                        ) => {
                                          return (
                                            <ProductDatabasePage
                                              {...props}
                                              catalogueTemplates={
                                                catalogueTemplates
                                              }
                                              isLoadingCatalogueTemplates={
                                                isLoadingCatalogueTemplates
                                              }
                                            />
                                          )
                                        }}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/project"
                                        redirectUrl="/home"
                                        hasAccess={isProductDatabaseEnabled}
                                        component={PremiumProjectPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/image-workflow"
                                        redirectUrl="/home"
                                        hasAccess={isImageWorkflowEnabled}
                                        component={ImageWorkflowPage}
                                      />
                                      <Route
                                        path="/collections/meta_desc"
                                        exact
                                        render={() => (
                                          <CollectionsPage
                                            adTextType={AdTextType.meta_desc}
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/collection"
                                        exact
                                        render={(props) => {
                                          return (
                                            <CollectionView
                                              {...props}
                                              onAfterGenerate={
                                                handleAfterGenerate
                                              }
                                            />
                                          )
                                        }}
                                      />
                                      <Route path="/integrations/webflow/install">
                                        <WebflowAuthorizationPage />
                                      </Route>
                                      <Route
                                        path="/catalog"
                                        exact
                                        render={(
                                          props: RouteComponentProps<{
                                            [x: string]: string | undefined
                                          }>
                                        ) => (
                                          // TODO: Typing for RouteComponentProps\
                                          <CatalogueView
                                            {...props}
                                            showTagImage={
                                              user.showTagImage ?? false
                                            }
                                            showImageTags={
                                              user.showImageTags ?? false
                                            }
                                            showWriterStyles={
                                              user.service_configs
                                                ?.product_descriptions
                                                ?.can_edit_writers ?? false
                                            }
                                            tagImageOnUpload={
                                              user.tagImageOnUpload ?? false
                                            }
                                            showSEORelatedKeywords={
                                              user.showSEORelatedKeywords ??
                                              false
                                            }
                                            editorType={user.editorType ?? ''}
                                            isMobile={isMobile}
                                            hlp={hlp}
                                            onAfterGenerate={
                                              handleAfterGenerate
                                            }
                                            planListId={
                                              planTierListObject?.plan_list_id
                                            }
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/bulk-workflows"
                                        exact
                                        component={BulkWorkflowDashboardPage}
                                      />
                                      <Route path="/advertising" exact>
                                        <Redirect to="/documents" />
                                      </Route>
                                      {adTextTabs.map((tab) => {
                                        return (
                                          <Route
                                            key={tab.key}
                                            path={
                                              marketingRoutes[
                                                tab.key as AdTextType
                                              ]
                                            }
                                            exact
                                            render={(props) => (
                                              <AdvertisingTextPage
                                                {...props}
                                                user={user}
                                                refreshUser={refreshUser}
                                                adTextType={tab.key}
                                              />
                                            )}
                                          />
                                        )
                                      })}
                                      <Route
                                        path="/bulk-workflow-folder"
                                        render={(
                                          props: RouteComponentProps<{
                                            [x: string]: string | undefined
                                          }>
                                        ) => (
                                          <BulkWorkflowPage
                                            {...props}
                                            onAfterGenerate={
                                              handleAfterGenerate
                                            }
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/image-generation"
                                        component={TextToImagePage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/settings"
                                        hasAccess={!disableSettingsPage}
                                        redirectUrl="/home"
                                        component={Settings}
                                      />
                                      <Route
                                        path="/documents"
                                        component={DocumentOverviewPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/automapper"
                                        component={AutomapperPage}
                                        hasAccess={isAutomapperEnabled}
                                        redirectUrl="/home"
                                      />
                                      <Route
                                        path="/documents/folder"
                                        component={DocumentOverviewPage}
                                      />
                                      <Route
                                        path="/blog-post"
                                        render={() => (
                                          <BlogGeneratorPage hlp={hlp} />
                                        )}
                                      />
                                      <Route
                                        path="/speech"
                                        component={SpeechPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/hypochat"
                                        hasAccess={true}
                                        redirectUrl="/home"
                                        component={ChatPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/feed"
                                        hasAccess={isHypoFeedEnabled}
                                        redirectUrl="/home"
                                        component={LiveFeedPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/document"
                                        hasAccess={true}
                                        redirectUrl="/home"
                                        component={DocumentPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        hasAccess={!disableOnboarding}
                                        redirectUrl="/"
                                        path="/onboard"
                                        exact
                                        component={OnboardingRedirect}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/admin"
                                        exact
                                        hasAccess={user.admin}
                                        redirectUrl="/home"
                                        component={AdminPanelPage}
                                      />
                                      <ProtectedRouteWithRedirect
                                        path="/image-editing/:workflowId"
                                        redirectUrl="/home"
                                        hasAccess={isAssetsManagementEnabled}
                                        component={ImageEditingPage}
                                      />
                                      <Route
                                        path="/checkout-success"
                                        exact
                                        component={CheckoutSuccessPage}
                                      />
                                      <Route
                                        path="/shopify-confirmation"
                                        exact
                                        component={
                                          ShopifyIntegrationConfirmationPage
                                        }
                                      />
                                      <Route
                                        path="/shopify-upgrade-success"
                                        exact
                                        component={
                                          ShopifyUpgradePlanConfirmationPage
                                        }
                                      />
                                      <Route component={NotFound} />
                                    </Switch>
                                  </CatalogueTemplateProvider>
                                </ProtectedRouteWithRedirect>
                              </ProtectedRouteWithRedirect>
                            </Switch>
                          </ProtectedRouteWithRedirect>
                        </ProtectedRouteWithRedirect>
                      </Switch>
                    </Sentry.ErrorBoundary>
                  </PremiumDrawerWithNavBar>
                </Suspense>
              }
              <G2Modal
                open={isShowingG2Modal}
                setIsShowingG2Modal={setIsShowingG2Modal}
                setIsShowingG2ReviewDrawerItems={
                  setIsShowingG2ReviewDrawerItems
                }
              />
            </OnboardingContextProvider>
          </div>
        </GenerationAPIProvider>
      </BeaconProvider>
    </UserProvider>
  ) : (
    <FullPageLoader />
  )
}
