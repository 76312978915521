import React, { Suspense } from 'react'

import { SnackbarProvider } from 'notistack'
import * as reactRouterDom from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import 'semantic-ui-css/semantic.min.css'
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui'
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui'
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui'
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import palette from '@hypotenuse/common/src/atoms/Colors'
import { CircularProgress } from '@material-ui/core'
import {
  ThemeProvider,
  createStyles,
  makeStyles
} from '@material-ui/core/styles'

import ArticlePreviewRedirect from '@hypotenuse/common/src/pages/public/ArticlePreviewRedirect'
import { SnackbarConfigurator } from '@hypotenuse/common/src/utils/Snackbar'

import {
  AUTH_ERROR_PAGE_PATH,
  DELETED_ACCOUNT_REDIRECT_PATH,
  DISABLED_ACCOUNT_REDIRECT_PATH,
  LOGIN_PAGE_PATH,
  LOGOUT_PAGE_PATH,
  POST_LOGIN_PAGE_PATH,
  SHADOW_BANNED_ACCOUNT_REDIRECT_PATH
} from './utils/Constants'
import theme from './utils/Theme'

// TODO: Lazy-load AuthenticatedApp
import AuthenticatedApp from './AuthenticatedApp'
import {
  AccountDisabledPage,
  AccountStatus
} from './components/pages/AccountDisabledPage'
import { AttemptRefreshPage } from './components/pages/AttemRefreshTokenPage'
// TODO: Lazy-load ReferralLandingPage
import ReferralLandingPage from './components/pages/ReferralLandingPage'
import AuthErrorPage from './components/pages/auth/AuthErrorPage'
import { LoginPage } from './components/pages/auth/LoginPage'
// TODO: Lazy-load LogoutPage
import LogoutPage from './components/pages/auth/LogoutPage'
import { PostLoginPage } from './components/pages/auth/PostLoginPage'
import './index.css'

const JoinTeamPage = React.lazy(() => import('./components/pages/JoinTeamPage'))

const useGlobalStyles = makeStyles(() =>
  createStyles({
    '@global': {
      'body ::-webkit-scrollbar': {
        width: '8px'
      },
      'body ::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      'body ::-webkit-scrollbar-thumb': {
        backgroundColor: palette.gray[200]
      }
    }
  })
)

export default function AppContainer() {
  const { showNewMessages } = useIntercom()
  useGlobalStyles()

  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          shouldRetryOnError: false
        }}
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            autoHideDuration={8000}
            disableWindowBlurListener
            preventDuplicate
          >
            <SnackbarConfigurator openChat={showNewMessages} />
            <Suspense
              fallback={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress size="3rem" />
                </div>
              }
            >
              <Switch>
                {/*Some select routes are accessible before login*/}
                {/*Join a team*/}
                <Route path="/join" component={JoinTeamPage} />
                {/*Referrals*/}
                <Route path="/invited" component={ReferralLandingPage} />
                {/*Public article preview sharing*/}
                <Route
                  path="/article-preview"
                  component={ArticlePreviewRedirect}
                />
                <Route path={LOGIN_PAGE_PATH} component={LoginPage} />
                <Route path={POST_LOGIN_PAGE_PATH} component={PostLoginPage} />
                <Route path={LOGOUT_PAGE_PATH} component={LogoutPage} />
                <Route path={AUTH_ERROR_PAGE_PATH} component={AuthErrorPage} />
                <Route
                  path={DISABLED_ACCOUNT_REDIRECT_PATH}
                  render={() => (
                    <AccountDisabledPage
                      accountStatus={AccountStatus.blocked}
                    />
                  )}
                />
                <Route
                  path={DELETED_ACCOUNT_REDIRECT_PATH}
                  render={() => (
                    <AccountDisabledPage
                      accountStatus={AccountStatus.deleted}
                    />
                  )}
                />
                <Route
                  path={SHADOW_BANNED_ACCOUNT_REDIRECT_PATH}
                  render={() => (
                    <AccountDisabledPage
                      accountStatus={AccountStatus.shadowBanned}
                    />
                  )}
                />{' '}
                <Route path="/refresh-session" component={AttemptRefreshPage} />
                {/*This renders the login UI on the / route*/}
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                  ThirdPartyPreBuiltUI,
                  EmailPasswordPreBuiltUI,
                  EmailVerificationPreBuiltUI
                ])}
                {/*All other routes are behind login*/}
                <Route path="" component={AuthenticatedApp} />
              </Switch>
            </Suspense>
          </SnackbarProvider>
        </ThemeProvider>
      </SWRConfig>
    </QueryParamProvider>
  )
}
