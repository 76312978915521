import { Retailers } from '../components/digitalShelfAnalytics/interfaces'

import { ContentEvaluationResult } from './ContentEvaluation'
import { ContentGenerationCategory } from './ContentGeneration'
import { ProductResearchResult } from './Research'

export interface ProductModifications {
  title?: string
  vendor?: string
  product_type?: string
  descriptionTags?: Array<string>
}

export interface ProductDetails {
  title: string
  sku?: string
  brand?: string
  imgSrc?: string
  multiImgSrcs?: string[]
  tags: Array<string>
  tagsDescription?: Array<string>
  category?: string
  metadata?: Partial<Record<string, any>>
  productModifications?: ProductModifications
  copyType?: string
  extra?: any
  field_mappings?: InputColumnNamesCSV
  /**
   * Existing description for the product, to be used as input for generation
   */
  existing_description?: string
  /**
   * Existing bullet points for the product, to be used as input for generation
   */
  existing_bullet_points?: string
  /**
   * Retailer where the product is imported from
   */
  retailer?: Retailers
  /**
   * UPC code for the product
   */
  upc?: string
}

export interface GenerationMetadata {
  stepper?: number
  enforce_proceed?: boolean
}

export enum GeneratedStatus {
  generation_succeeded = 'generation_succeeded',
  generation_not_started = 'generation_not_started',
  generation_failed = 'generation_failed'
}

export interface Product {
  productId: string
  catalogueId: string
  productDetails: ProductDetails
  product_research_results?: Array<ProductResearchResult>
  enrich_url?: string
  descriptions: Array<Description>
  loading: boolean
  loadingSEO: boolean
  loadingTag: boolean
  createdAt: string
  creditCost: number
  isRegenerate: boolean
  loadingAt?: Date
  loadingTagAt?: string
  timesTagged?: number
  isSaved?: boolean
  isSavedAt?: string
  isReviewing?: boolean
  productErrorType?: ProductErrorType
  productErrorMessage?: string
  numGenerated: number
  disabled?: boolean
  hidden?: boolean
  tagsGenerated?: string[]
  isMinimized?: boolean
  currentStepper?: number
  proceedCost?: number | null
  expiredAt?: Date
  requiredDescriptionStyles?: string[][]
  generationHistory?: GenerationMetadata[]
  selectedSeoKeyword?: string
  seoKeywords?: string[]
  relatedKeywords?: string[]
  avoidKeywords?: string[]
  archived?: boolean
  generation_type?: ContentGenerationCategory
  rootKeyword?: string
  seo_keyword_suggestions?: SeoKeywordSuggestion[]
  product_page_url?: string
  enrich_from_web_status?: GeneratedStatus
  enrich_from_url_status?: GeneratedStatus
  enrich_from_image_status?: GeneratedStatus
  enrich_from_upc_status?: GeneratedStatus
  seo_keyword_generated_status?: GeneratedStatus
  show_generation_errors?: boolean
}

export interface ProductUpdateForm {
  productId: string
  productDetails?: ProductDetails
  product_research_results?: Array<ProductResearchResult>
  enrich_url?: string
  descriptions?: Description[]
  productErrorType?: ProductErrorType
  productErrorMessage?: string
  isMinimized?: boolean
  tagsGenerated?: string[]
  archived?: boolean
  currentStepper?: number
  selectedSeoKeyword?: string
  seoKeywords?: string[]
  seo_keyword_suggestions?: SeoKeywordSuggestion[]
  relatedKeywords?: string[]
  avoidKeywords?: string[]
  rootKeyword?: string
  product_page_url?: string
  loading?: boolean
  show_generation_errors?: boolean
}

export interface ProductLoadingState {
  loading?: boolean
  loadingAt?: Date
  expiredAt?: Date
}

export interface ProductStatusUpdateForm {
  productId: string
  isSaved?: boolean
  isReviewing?: boolean
  catalogue_template_name?: string
}

export interface ProductUpdateFormExtraFields {
  catalogue_template_name?: string
}

export enum ProductErrorType {
  scrapingError = 'scrapingError',
  generateDescriptionsError = 'generateDescriptionsError',
  blankResponseError = 'blankResponseError',
  translationError = 'translationError',
  resolved = 'resolved' // Need this to indicate there is no error in the product
}

export enum ProductImportSource {
  csvExcel = 'csv_excel',
  shopify = 'shopify',
  wooCommerce = 'woo_commerce',
  bigCommerce = 'big_commerce',
  airTable = 'airtable',
  amazon = 'amazon',
  contentful = 'contentful',
  customPim = 'custom_pim'
}

export const productImportSourceToCardId: Record<
  ProductImportSource,
  string
> = {
  [ProductImportSource.csvExcel]: 'catalogue-import-products-source-csv-card',
  [ProductImportSource.shopify]:
    'catalogue-import-products-source-shopify-card',
  [ProductImportSource.wooCommerce]:
    'catalogue-import-products-source-woocommerce-card',
  [ProductImportSource.bigCommerce]:
    'catalogue-import-products-source-bigcommerce-card',
  [ProductImportSource.airTable]:
    'catalogue-import-products-source-airtable-card',
  [ProductImportSource.amazon]: 'catalogue-import-products-source-amazon-card',
  [ProductImportSource.contentful]:
    'catalogue-import-products-source-contentful-card',
  [ProductImportSource.customPim]:
    'catalogue-import-products-source-custom-pim-card'
}

export interface Description {
  description_id?: string
  content: string
  selected: boolean
  deleted: boolean
  original?: string
  streaming?: boolean
  replaced?: boolean
  theme?: string
  color?: string
  show_append_original?: boolean
  downvoted?: boolean
  selected_at?: string
  downvote_details?: DescriptionDownvoteDetails
  content_evaluation_results?: ContentEvaluationResult[]
  name_group?: string
  writer_description_index?: number
  description_extraction_id?: string
  referenced_image_ids?: string[]
  created_at?: string
  /**
   * Language in which the content has been generated.
   *
   * This attribute stores the language code representing the output language
   * of the generated content (e.g., 'EN' for English, 'FR' for French).
   */
  output_language?: string
  /**
   * Language of the original content, from which the description has been generated.
   * If `None`, it's assumed that the content is not a translation.
   */
  translation_source_language?: string
  /**
   * Display name of the tone used for the generation.
   */
  tone_display_name?: string
  /**
   * Display name of the writer used for the generation.
   */
  writer_display_name?: string
}

export interface DescriptionDownvoteDetails {
  reasons: string[]
  available_reasons?: string[]
  explanation: string
}

export enum ProductStepper {
  NAME,
  SHORT_DESCRIPTION,
  LONG_DESCRIPTION
}

export interface InputColumnNamesCSV {
  [key: string]: string | string[]
}

/**
 * Mapping from product fields to source attributes
 * TODO: refactor such we don't inherit from InputColumnNamesCSV (done for backwards compatibility)
 */
export interface FieldMapping extends InputColumnNamesCSV {}

export interface ProductDetailsMetadataImage {
  updated_at: string
  src: string
  product_id: number
  admin_graphql_api_id: string
  width: number
  created_at: string
  id: number
  position: number
  height: number
}

export interface SeoKeywordSuggestion {
  keyword: string
  keyword_difficulty?: number
  keyword_volume?: number
}

export interface AddSeoKeywordSuggestionResponse {
  product_id: string
  seo_keyword_suggestions: SeoKeywordSuggestion[]
  selected_seo_keyword: string
  seo_keywords: string[]
}

export enum TabKey {
  new = 'new',
  generated = 'generated',
  reviewing = 'reviewing',
  saved = 'saved',
  all = 'all'
}

export const ProductTabLabel = {
  [TabKey.new]: 'New',
  [TabKey.generated]: 'Generated',
  [TabKey.reviewing]: 'For Review',
  [TabKey.saved]: 'Done',
  [TabKey.all]: 'All'
}

export enum GenerationStatusFilterKey {
  all = 'all',
  generated = 'generated',
  not_generated = 'not_generated',
  generation_error = 'generation_error'
}

export const GenerationStatusFilterLabel = {
  [GenerationStatusFilterKey.all]: 'All',
  [GenerationStatusFilterKey.generated]: 'Generated',
  [GenerationStatusFilterKey.not_generated]: 'Not Generated',
  [GenerationStatusFilterKey.generation_error]: 'Failed to Generate'
}

export const GenerationStatusFilterLabelForCatalogue = {
  [GenerationStatusFilterKey.all]: 'All',
  [GenerationStatusFilterKey.generated]: 'Generated on last try',
  [GenerationStatusFilterKey.not_generated]: 'Not generated',
  [GenerationStatusFilterKey.generation_error]: 'Failed on last try'
}

export enum GenerationFilterType {
  descriptions = 'descriptions',
  enrich_from_web = 'enrich_from_web',
  enrich_from_url = 'enrich_from_url',
  enrich_from_image = 'enrich_from_image',
  enrich_from_upc = 'enrich_from_upc',
  seo_keywords = 'seo_keywords'
}

export const GenerationFilterTypeLabel = {
  [GenerationFilterType.descriptions]: 'Descriptions',
  [GenerationFilterType.enrich_from_web]: 'Enrich from Web',
  [GenerationFilterType.enrich_from_url]: 'Enrich from URL',
  [GenerationFilterType.enrich_from_image]: 'Enrich from Image',
  [GenerationFilterType.enrich_from_upc]: 'Enrich from UPC',
  [GenerationFilterType.seo_keywords]: 'SEO Keywords'
}

export interface GenerationFilter {
  type: GenerationFilterType
  status: GenerationStatusFilterKey[]
}

// Same as GenerationFilter, but with optional status, for display purposes
export interface IGenerationFilterDisplay {
  type: GenerationFilterType
  status?: GenerationStatusFilterKey[]
}

export interface CatalogueFilter {
  catalogueId?: string
  tab?: TabKey
  generationStatus?: GenerationStatusFilterKey
  generationFilters?: GenerationFilter[]
  search?: string
  brand?: Array<string>
  tags?: Array<string>
  category?: Array<string>
  startDate?: Date
  endDate?: Date
  scan?: boolean
  sortBy?: (string | Record<string, any>)[] | undefined
  /**
   * Shopify store(s) that must be stored in
   * the metadata of the fetched catalog product(s).
   */
  shopifyStoreNameList?: string[]
  /**
   * Whether a PDB product item ID is stored in
   * the metadata of the fetched catalog product(s).
   *
   * If left unspecified, the query ignores this filter.
   */
  hasPDBProductItemId?: boolean
}

export interface ProductSearchQuery extends CatalogueFilter {
  // For pagination
  start?: number
  end?: number
}

export enum ImportErrorState {
  // This should follow the enum in the backend in app/products/schemas/product.py
  MISSING_SKU_FIELD = 'missing_sku_field'
}

export const ImportErrorStateMessage: Record<string, string> = {
  [ImportErrorState.MISSING_SKU_FIELD]:
    'Some products are missing the SKU field. Please ensure that all products have a SKU field.'
}

export interface DuplicatedProduct {
  product_title: string
  sku: string
}

export interface DuplicateProductResponse {
  duplicated_products: DuplicatedProduct[]
  error?: ImportErrorState
}

export interface InspectCSVResponse {
  missing_all_value_columns: string[]
  missing_value_columns: string[]
  rows: Record<string, string[]>
}

/**
 * Provides additional information about the source attributes that can be mapped to the fields.
 */
export interface AdditionalSourceAttributeInfo {
  /**
   * List of attributes where there is no value defined for all entities
   */
  attributes_without_values: string[]
  /**
   * List of attributes where there is no value defined for some entities
   */
  attributes_with_partial_values: string[]
  /**
   * List of attributes where that is a maximum of one value for each entity
   */
  attributes_with_single_values: string[]
  /**
   * List of sample values for each attribute
   */
  sample_values_for_attributes: Record<string, string[]>
}

export interface Mappings {
  id: string
  mappings: Record<string, string[]> | null
}

export enum MappingTypeEnum {
  Catalog = 'catalog',
  ProductDatabaseCSVUpload = 'product_database_csv_upload'
}

/**
 * Keys in the CatalogueTemplate input mapping
 * These are in snake_case to match the DB values.
 */
export enum CatalogueTemplateMappingKeys {
  title = 'title',
  brand = 'brand',
  tags = 'tags',
  seo_keywords = 'seo_keywords',
  existing_description = 'existing_description',
  existing_bullet_points = 'existing_bullet_points',
  image = 'image',
  avoid_keywords = 'avoid_keywords',
  retailer = 'retailer',
  product_page_url = 'product_page_url',
  upc = 'upc',
  enrich_url = 'enrich_url'
}

export type CatalogueTemplateMappingKeyType = keyof typeof CatalogueTemplateMappingKeys

export interface MappingFieldInformation {
  id: string
  display_name: string
  required: boolean
  multi: boolean
  help_text: string
}

export const fieldHelpText: Record<string, string> = {
  title:
    "Select a field that matches the product's name (e.g., Red Hot Cut-Out Midi Dress) to serve as the product title.",
  sku:
    'Select a field that matches the SKU, a unique code identifying each product (e.g., ABJHA00267), to ensure every product has a unique identifier.',
  brand: "Select a field that matches the product's brand (e.g., Adidas).",
  tags:
    'Select one or more fields that describe the product, like dimensions, materials, features, or functions. These will appear as multiple tags.',
  seoKeywords:
    'Select one or more fields containing SEO keywords to help the product webpage rank on search engines. These will appear as multiple tags.',
  existingDescription:
    "Select a field that matches the product's description. This should be the existing written details about the product.",
  existingBullets:
    "Select one or more fields that contain the product's main features as short bullet points. These should be the existing written bullets describing the product",
  avoidKeywords:
    'Add the keywords that should not appear in the descriptions generated',
  enrichUrl:
    "Select a field that matches the product's information URL. This should be a link to a webpage with details about the product.",
  retailer: 'Select a field name that matches the retailer name',
  product_page_url: 'Select a field name that matches the product page URL',
  upc: 'Select a field name that matches the UPC code',
  multiImgSrcs: 'Select one or more field names that match the image URLs'
}

export const fixedFields = [
  {
    id: 'title',
    display_name: 'Product title',
    required: true,
    multi: false
  },
  {
    id: 'sku',
    display_name: 'SKU',
    required: true,
    multi: false
  },
  {
    id: 'brand',
    display_name: 'Brand',
    required: false,
    multi: false
  },
  {
    id: 'tags',
    display_name: 'Tags',
    required: false,
    multi: true
  },
  {
    id: 'seoKeywords',
    display_name: 'SEO Keywords',
    required: false,
    multi: true
  },
  {
    id: 'enrichUrl',
    display_name: 'Enrich URL',
    required: false,
    multi: false
  },
  {
    id: 'existingDescription',
    display_name: 'Simple Description',
    required: false,
    multi: false
  },
  {
    id: 'existingBullets',
    display_name: 'Technical Descriptions',
    required: false,
    multi: true
  },
  {
    id: 'upc',
    display_name: 'UPC',
    required: false,
    multi: false
  }
]

export const multiValueFieldsSet = new Set([
  'tags',
  'seoKeywords',
  'avoidKeywords',
  'existingBullets',
  'multiImgSrcs'
])

export interface TemplateFieldDetails {
  display_name?: string
  is_required?: boolean
  help_text?: string
}

export const fixedFieldsMappings = (isSkuRequired: boolean) => {
  return {
    sku: {
      display_name: 'SKU',
      is_required: isSkuRequired
    },
    multiImgSrcs: {
      display_name: 'Images',
      is_required: false
    }
  }
}

export const defaultFieldMappings = (isSkuRequired: boolean) => {
  return {
    title: {
      display_name: 'Product title',
      is_required: true
    },
    sku: {
      display_name: 'SKU',
      is_required: isSkuRequired
    },
    brand: {
      display_name: 'Brand',
      is_required: false
    },
    multiImgSrcs: {
      display_name: 'Images',
      is_required: false
    },
    tags: {
      display_name: 'Tags',
      is_required: false
    },
    seoKeywords: {
      display_name: 'SEO Keywords',
      is_required: false
    }
  }
}

export const templateToStandardMapping: Record<
  CatalogueTemplateMappingKeyType,
  string
> = {
  title: 'title',
  brand: 'brand',
  tags: 'tags',
  seo_keywords: 'seoKeywords',
  existing_description: 'existingDescription',
  existing_bullet_points: 'existingBullets',
  image: 'multiImgSrcs',
  avoid_keywords: 'avoidKeywords',
  retailer: 'retailer',
  product_page_url: 'product_page_url',
  upc: 'upc',
  enrich_url: 'enrichUrl'
}

export const standardToTemplateMapping: Record<
  string,
  CatalogueTemplateMappingKeyType
> = {
  title: CatalogueTemplateMappingKeys.title,
  brand: CatalogueTemplateMappingKeys.brand,
  tags: CatalogueTemplateMappingKeys.tags,
  seoKeywords: CatalogueTemplateMappingKeys.seo_keywords,
  existingDescription: CatalogueTemplateMappingKeys.existing_description,
  existingBullets: CatalogueTemplateMappingKeys.existing_bullet_points,
  multiImgSrcs: CatalogueTemplateMappingKeys.image,
  avoidKeywords: CatalogueTemplateMappingKeys.avoid_keywords,
  retailer: CatalogueTemplateMappingKeys.retailer,
  product_page_url: CatalogueTemplateMappingKeys.product_page_url,
  upc: CatalogueTemplateMappingKeys.upc,
  enrichUrl: CatalogueTemplateMappingKeys.enrich_url
}

export const enrichmentFieldsUrl = {
  enrichUrl: {
    display_name: 'Enrich URL',
    is_required: false
  }
}

export const enrichmentFieldsUpc = {
  upc: {
    display_name: 'UPC',
    is_required: false
  }
}

export const fieldsOrderList = [
  'title',
  'sku',
  'brand',
  'tags',
  'multiImgSrcs',
  'seoKeywords',
  'enrichUrl',
  'retailer',
  'existingDescription',
  'existingBullets',
  'upc',
  'avoidKeywords'
]

export const fieldsOrderMapping: Record<string, number> = {
  title: 0,
  sku: 1,
  brand: 2,
  tags: 3,
  multiImgSrcs: 4,
  seoKeywords: 5,
  enrichUrl: 6,
  retailer: 7,
  existingDescription: 8,
  existingBullets: 9,
  upc: 10,
  avoidKeywords: 11,
  product_page_url: 12
}

export const DEFAULT_ORDER_VALUE = 100

/**
 * Comparison function to sort fields based on the order defined in `fieldsOrderMapping`.
 * If the field is not defined in the mapping, it will be sorted based on the field ID.
 * @param a First field to compare
 * @param b Second field to compare
 */
export const fieldsOrderComparisonFunction = (
  a: MappingFieldInformation,
  b: MappingFieldInformation
) => {
  const primaryComparisonValue =
    (fieldsOrderMapping?.[a.id] ?? DEFAULT_ORDER_VALUE) -
    (fieldsOrderMapping?.[b.id] ?? DEFAULT_ORDER_VALUE)
  const secondaryComparisonValue = a.id < b.id ? -1 : a.id > b.id ? 1 : 0
  return primaryComparisonValue !== 0
    ? primaryComparisonValue
    : secondaryComparisonValue
}

/**
 * Response for the product description pipeline-based
 * generation endpoint.
 */
export interface ProductDescriptionGenerationResponse {
  warning_message: string[]
}

export interface AttributeToMap {
  identifier: string
  displayName: string
}

export interface ProductDataExtractionResult {
  title: string
  brand: string
  product_description?: string
  product_bullets?: string
  image?: string
}

export interface ProductDataExtractionResultResponse {
  data: ProductDataExtractionResult
  has_inputs: boolean
  has_missing_inputs: boolean
}

export interface EnrichmentMissingInputsResponse {
  products_missing_web_inputs?: Product[]
  products_missing_url_inputs?: Product[]
  products_missing_img_inputs?: Product[]
  products_missing_upc_inputs?: Product[]
  products_missing_fallback_inputs?: Product[]
}
